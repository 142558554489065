<template>
  <div class="mv"
       v-loading="loading">
    <div class="filter-wrap">

      <div class="section-wrap">
        <span class="section-name">地区：</span>
        <ul class="section-tabs">
          <li><span :class="{'active':region=='全部'}"
                  @click="region='全部'">全部</span></li>
          <li><span :class="{'active':region=='内地'}"
                  @click="region='内地'">内地</span></li>
          <li><span :class="{'active':region=='港台'}"
                  @click="region='港台'">港台</span></li>
          <li><span :class="{'active':region=='欧美'}"
                  @click="region='欧美'">欧美</span></li>
          <li><span :class="{'active':region=='日本'}"
                  @click="region='日本'">日本</span></li>
          <li><span :class="{'active':region=='韩国'}"
                  @click="region='韩国'">韩国</span></li>
        </ul>
      </div>

      <div class="section-wrap">
        <span class="section-name">类型：</span>
        <ul class="section-tabs">
          <li><span :class="{'active':type=='全部'}"
                  @click="type='全部'">全部</span></li>
          <li><span :class="{'active':type=='官方版'}"
                  @click="type='官方版'">官方版</span></li>
          <li><span :class="{'active':type=='原声'}"
                  @click="type='原声'">原声</span></li>
          <li><span :class="{'active':type=='现场版'}"
                  @click="type='现场版'">现场版</span></li>
          <li><span :class="{'active':type=='网易出品'}"
                  @click="type='网易出品'">网易出品</span></li>
        </ul>
      </div>

      <div class="section-wrap">
        <span class="section-name">排序：</span>
        <ul class="section-tabs">
          <li><span :class="{'active':order=='上升最快'}"
                  @click="order='上升最快'">上升最快</span></li>
          <li><span :class="{'active':order=='最热'}"
                  @click="order='最热'">最热</span></li>
          <li><span :class="{'active':order=='最新'}"
                  @click="order='最新'">最新</span></li>
        </ul>
      </div>

    </div>

    <div class="mv-wrap">
      <ul class="mv-list">
        <li v-for="(item,index) in mvList"
            :key="index">
          <div class="mv-img-wrap"
               @click="toMvDetail(item.id)">
            <img v-lazy="item.cover"
                 alt="newMvs">
            <p class="iconfont icon-play play"></p>
            <p class="play-count iconfont icon-play">{{item.playCount | ellipsisPlayVolume}}</p>
          </div>
          <div class="mv-info">
            <p class="title"
               :title="item.name">{{item.name}}</p>
            <p class="author">{{item.artistName}}</p>
          </div>
        </li>
      </ul>
    </div>

    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   background
                   :layout="pagi"
                   :total="total"
                   :page-sizes="[12, 16, 28, 32]"
                   :page-size="pageSize"
                   :current-page="page">
    </el-pagination>

  </div>
</template>

<script >
import { mvListAPI } from '@/api/api'

export default {
  data () {
    return {
      region: '全部',
      type: '全部',
      order: '上升最快',
      total: 0,
      page: 1,
      pageSize: 12,
      mvList: [],
      loading: true,
      pagi: 'total, sizes, prev, pager, next, jumper'
    }
  },
  created () {
    this.getMvList()
  },
  watch: {
    region () {
      this.page = 1
      this.getMvList()
    },
    type () {
      this.page = 1
      this.getMvList()
    },
    order () {
      this.page = 1
      this.getMvList()
    }
  },
  methods: {
    toMvDetail (id) {
      this.$router.push(`/mvdetail?id=${id}`)
    },
    handleCurrentChange (page) {
      this.page = page
      this.getMvList(true)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getMvList(true)
    },
    async getMvList (changeTotal = false) {
      this.loading = true
      const params = {
        area: this.region,
        type: this.type,
        order: this.order,
        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize
      }
      const { data: res } = await mvListAPI(params)
      if (!changeTotal) {
        this.total = res.count
      }
      this.mvList = res.data
      this.loading = false
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.pagi = 'prev, jumper ,next'
    }
  }
}
</script>

<style scoped lang="less">
.mv {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.section-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 14px;
}

.section-tabs {
  display: flex;
  color: grey;
}

.section-tabs li:not(:last-of-type) {
  border-right: 1px solid #f2f2f1;
}

.section-tabs li span {
  margin: 20px;
  padding: 5px 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.section-tabs .active {
  color: #ff0000;
  background-color: #fcf6f5;
  border-radius: 20px;
}

.mv-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.mv-list li {
  width: 23%;
  margin-bottom: 20px;
  margin-right: 20px;
}

.mv-img-wrap {
  position: relative;
  height: 165px;
  cursor: pointer;
}

.mv-img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.mv-img-wrap:hover .play::before {
  opacity: 1;
}

.mv-img-wrap .play::before {
  content: '\e665';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
}

.play-count {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  text-shadow: 0 0 2px rgb(0, 0, 0);
}

.play-count::before {
  margin-right: 5px;
}

.mv-info p {
  margin: 10px 0;
}

.mv-info p.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mv-info .author {
  color: #a5a1a1;
  font-size: 12px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ec4141;
}
/deep/.el-pagination.is-background .el-pager li {
  border-radius: 5px !important;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ec4141;
}

@media screen and (max-width: 479px) {
  .mv {
    width: 80vw;
    padding: 0;
  }
  /deep/ .el-pagination__jump {
    margin: 0 2vw !important;
  }
  .filter-wrap {
    width: 80vw;
  }
  .section-wrap {
    flex-wrap: wrap;
    margin-top: 2vw;
    margin-bottom: 3vw;
    font-size: 14px;
  }
  .section-tabs li span {
    margin: 0 1vw;
    padding: 0px 0px;
  }
  .mv-img-wrap {
    position: relative;
    width: 100%;
    height: 26vw;
    cursor: pointer;
  }
  .mv-list li {
    width: 38vw;
    margin: 1.5vw 0;
  }
  .mv-info p {
    margin: 2vw 0;
  }
  .mv-info .title {
    font-size: 13px;
  }
  .play-count {
    font-size: 10px;
  }
  .el-pagination {
    margin: 2vw 0;
  }
  .mv-list {
    display: flex;
    justify-content: space-between;
  }
}
</style>
